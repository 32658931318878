// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.settingsEndPoint = "/bx_block_kanbanboard/settings"
exports.sectionEndPoint = "/bx_block_kanbanboard/sections"
exports.Role = 'role';
exports.searchEndPoint = "bx_block_advanced_search/search/search"
exports.ProjectManagerRole = "project_manager";
exports.Secretkey = "AbCdeFgHiJkLmNoPqRsTuVwXyZ0123456789";
exports.Token = 'token';
exports.ParticipantRole = "participant";
exports.visible = "visible";
exports.hidden = "hidden";
exports.menuTypeColumn = 'column';
exports.menuTypeTaskActions = 'task-action';
exports.projectStartTimeFormat = "MMMM D, YYYY";
exports.taskDueDateFormat = "DD MMM, YYYY";
exports.AddColumn = "Add Column";
exports.TypeColumn = "COLUMN";
exports.TypeCustom = "custom";
exports.TypeCard = "CARD";
exports.CreatedBy = "Created by";
exports.ConfirmDeleteTask = "Are you sure you want to delete column from the task?";
exports.MoveTaskHere = "Move task here";
exports.AddTask = "Add Task";
exports.ModalTitleDelete = "Delete Column";
exports.AddColumnId = "add";
exports.NoTask = "No Task found !";
exports.Rename = "Rename";
exports.TryCreatingTasks = "Try creating a new task.";
exports.draggableIdPlaceholder = "card-placeholder";
exports.BtnCancel = "Cancel";
exports.DrapppableColId = "all-columns";
exports.DeleteColumn = "Delete";
exports.BtnDelete = "Delete";
exports.ColumnToDo = "to_do";
exports.Duplicate = "Duplicate";
exports.DueBy = "Due by -";
exports.Taskdesc = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corporis est sapiente, repellat incidunt et voluptate iure aliquam totam eius deleniti quam sed distinctio nihil, tenetur cum excepturi! Est, aliquid? Distinctio."
exports.ColumnNeedReview = "need_review";
exports.ColumnCompleted = "completed";
exports.DELETE = "DELETE";
exports.POST = "POST";
exports.GET = "GET";
exports.PATCH = "PATCH";
exports.PUT = "PUT";
exports.tasksEndPoint = "bx_block_tasks/tasks";
exports.ColumnInProgress = "in_progress";
exports.tasks_kanban_menu = "tasks_kanban_menu";
exports.ContentType = "application/json";
exports.columnDefault = "default";
exports.customColumnEndPoint = "bx_block_kanbanboard/sections";
exports.AddNewColumn = "Add new column";
exports.sectionableType = "BxBlockProjectportfolio::Project";
exports.TaskDescription = "task-description";
exports.PhaseTool = "phases-tool";
exports.AddMembers = "add-member";
exports.AddTaskDetails = "add-task-details";
exports.DatePicker = "date-picker";
exports.phase5DownloadLinkEndPoint = 'bx_block_projecttemplates/phases/phase5_tool_pdf_download?query=';
exports.AddParticipantMessage = "Please add participants to the project in order to create a new task!";
exports.NotStartedMessage = "The project has not started yet. Please change the status to 'In Progress' to add a new task!";
exports.OnHoldMessage = "The project is on hold; you can't add a new task at the moment!";
exports.CannotViewTaskError= "You cannot view the task details because the project is not active.";
exports.CompletedErrorMessage = "The project is completed; you can't add a new task now!";
exports.AddMorePhaseData = [
  {
    question: "Write the actions in this phase : ",
    preFix: 'Action',
    fields: [
      {
        key: 1,
        content: '',
        ans_type: "default",
        error: ''
      }
    ]
  },
  {
    question: "Write all the thoughts of the user in this phase : ",
    preFix: 'Thought',
    fields: [
      {
        key: 1,
        content: '',
        ans_type: "default",
        error: ''
      }
    ]
  },
  {
    question: "Write all the feelings of the user in this phase : ",
    preFix: 'Feeling',
    fields: [
      {
        key: 1,
        content: '',
        ans_type: "default",
        error: ''
      }
    ]
  }
];
exports.IDEA_ARRAY = [
  {
    question: "Idea",
    placeHolder: "Enter your idea...",
  },
  {
    question: "factor 1",
    placeHolder: "Enter the factor1...",
  },
  {
    question: "factor 2",
    placeHolder: "Enter the factor2...",
  }
];
exports.ADDROUND = {
  round: "Round",
  subQuestion: {
    question: "Write your ideas",
    preFix: '1',
    fields: [
      {
        key: 1,
        content: '',
        ans_type: "default",
        error: ''
      }
    ]
  }
};
exports.ToolName = {
  tool3: "Tool 3 - Customer Journey Map",
  phase3tool1: 'Tool 1 - \"How might we...\" Question',
  phase4tool2: 'Tool 2 - 2x2 Matrix',
  phase4Toool1: "Tool 1 - Brainstorming"
}
exports.ErrorMessage = {
  pleaseFillTheField: "please fill this field",
  phaseNameRequired: "Phase name is required",
  fileSizeError: 'File size exceeds the maximum allowed limit. Please upload a file smaller than 5 MB.',
  taskNameRequired: 'Task name is required',
  taskDescriptionRequired: 'Task description is required',
  dueDateRequired: 'Due date is required',
  subtaskRequired: 'Subtask is required',
  subtaskAddMoreError: 'please fill the empty subtask to add more',
  selectPhaseError: 'Please select the phase',
  selectToolError: 'Please select the tool',
  cannotMoveInprogressToNeedReview:"Cannot move the task to review as there are some subtasks that are either not submitted or have not been acted upon.",
  cannotMoveNeedReviewToCompleted:"There are some subtasks that have not been reviewed yet. Please review and accept them to mark the task complete."
}
exports.ONES = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
exports.TEENS = ['eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
exports.TENS = ['ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
exports.DEFAULT_COLUMNS = ["To Do", "In Progress", "Need Review", "Completed", "Add new column"];
exports.PHASE3TOOL1 = {
  FinalStatement: "Final Statement:"
};
exports.ENDPOINTS = {
  baseTask:'bx_block_tasks/tasks',
  baseSection:'bx_block_kanbanboard/sections',
  baseComments:'bx_block_comments/comments',
  baseProjects:'bx_block_projectportfolio/projects',
  basePhases:"bx_block_projecttemplates/phases"
}
// Customizable Area End
