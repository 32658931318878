import React, { useEffect } from 'react';
import { Box, IconButton, InputAdornment, InputLabel, TextField } from '@material-ui/core';
import { Button, Typography ,styled} from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InputField from './InputField';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import CommonSelect from './CommonSelect';

interface Props {
    onChange?: (text: Object, isRelationFieldChange: boolean) => void;
    addMoreFields?: () => void;
    removeField?: (key: number) => void;
    iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onChangeDefaultField?: (value: string, key: number) => void;
    relation?: { key: number, content: string; ans_type: string; error: string },
    toggleRelationship?: (key: number) => void;
    onLoadAddFields?: () => void;
    value?: { key: number, content: string; ans_type: string; isRelationDefault?: boolean; error: string }[];
    placeholder?: string;
    disabled?: boolean;
    helperText?: string;
    error?: boolean;
    label?: string;
    style?: React.CSSProperties;
    placeHolderPrefix?: string;
    fullWidth?: boolean;
    asterisk?: boolean;
    disabledAddMore?: boolean;
    labelStyle?: GenericStyle;
    readOnly?: boolean;
    subLabel?: string;
    isHavingRelation?: boolean;
}

interface GenericStyle {
    [key: string]: string;
}

const StyledInputField = styled(TextField)({
    '& .MuiInput-underline:hover::before': {
        borderBottom: "1px solid #CBD5E1"
    },
    "& .MuiInput-underline:hover": {
        borderBottom: "1px solid #CBD5E1",
    },
    "& .MuiInput-underline::before": {
        display: 'none',
    },
    '& .MuiInput-underline::after': {
        borderWidth: '1px'
    },
    "& .MuiInput-underline.Mui-focused": {
        borderBottom: "1px solid #5B4595",
    },
    '& .MuiInput-underline': {
        borderBottom: "1px solid #CBD5E1",
    },
    "& .MuiInputBase-root": {
        paddingLeft: "0px"
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
    },
    '& .MuiInput-underline.Mui-focused::after': {
        display: 'none',
    },
    '&.MuiInput-underline.Mui-error:after': {
        borderBottom: '1px solid #f44336'
    }
});

const list = [
    { value: "direct influence", label: "direct influence" },
    { value: "two direct influence", label: "two direct influence" },
    { value: "undirect influence", label: "undirect influence" }
]

const CommonMultipleAnswerField = (props: Props) => {
    const {
        onChange,
        value,
        placeholder,
        disabledAddMore,
        onLoadAddFields,
        disabled,
        error,
        helperText,
        placeHolderPrefix,
        label,
        subLabel,
        style,
        addMoreFields,
        relation,
        onChangeDefaultField,
        removeField,
        fullWidth = false,
        asterisk = false,
        labelStyle,
        isHavingRelation = false,
        readOnly = false,
        iButtonClick,
        toggleRelationship,
        ...rest
    } = props;

    useEffect(() => {
        if (onLoadAddFields) {
            onLoadAddFields();
        }
    }, [])
    return <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
        {label &&
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel style={{ ...webStyles.label, ...labelStyle }} >{label} {asterisk && <span style={{ color: 'red' }} >*</span>}
                </InputLabel>
                {iButtonClick && <IconButton data-test-id='i' onClick={iButtonClick} style={{ width: '16px', height: '16px', padding: '0 20px' }} > <InfoRoundedIcon style={{ color: '#64748B', width: '16px', height: '16px' }} /> </IconButton>}
            </Box>
        }
        {subLabel ? <StyledSubLabel>{subLabel}</StyledSubLabel> : null}
        <Box style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', padding: '18.5px 14px', ...style }}>
            {value?.map((fieldData, indx) => {
                return <>
                    {fieldData.ans_type === 'default' ? <Box sx={{ display: 'flex' }}>
                        <StyledInputField
                            data-test-id='default-field'
                            disabled={disabled}
                            key={fieldData.key}
                            style={{ marginBottom: '10px' }}
                            fullWidth
                            value={fieldData.content}
                            onChange={(event) => onChangeDefaultField && onChangeDefaultField(event.target.value, fieldData.key)}
                            variant='standard'
                            inputProps={{ maxLength: 80 }}
                            error={Boolean(fieldData.error)}
                            helperText={fieldData.error}
                            InputProps={{
                                readOnly: readOnly,
                                startAdornment:
                                    <InputAdornment position="end">
                                        <Typography style={{ color: '#64748B' }}>{placeHolderPrefix ?? ''} {isHavingRelation ? fieldData.key < 3 ? 1 : fieldData.key - Math.floor(fieldData.key / 2) : fieldData.key} : &nbsp;</Typography>
                                    </InputAdornment>,
                                endAdornment:
                                    <InputAdornment position="end">
                                        {isHavingRelation ? null :
                                            (!isHavingRelation && indx === 0) ? null :
                                                !disabled && <IconButton disabled={disabled} onClick={() => removeField && removeField(fieldData.key)} >
                                                    <DeleteOutlineRoundedIcon />
                                                </IconButton>}
                                    </InputAdornment>
                            }}
                        />
                        {indx % 2 === 0 && isHavingRelation && indx !== 0 && <IconButton data-test-id='delete-rel' disabled={disabled} style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '8px', marginLeft: '20px' }} onClick={() => removeField && removeField(fieldData.key)} >
                            <DeleteOutlineRoundedIcon />
                        </IconButton>}
                    </Box>
                        : <>
                            {(fieldData.isRelationDefault || fieldData.isRelationDefault === undefined) ? <CommonSelect
                                fullWidth
                                value={fieldData?.content}
                                disabled={disabled}
                                data-test-id='relation-field'
                                error={Boolean(fieldData?.error)}
                                helperText={fieldData?.error}
                                onChange={(text) => onChangeDefaultField && onChangeDefaultField(text as string, fieldData!.key)}
                                label='Relationship :'
                                list={list}
                                placeholder='Select relationship'
                            /> :
                                <InputField
                                    value={fieldData?.content}
                                    disabled={disabled}
                                    data-test-id='relation-field'
                                    inputProps={{ maxLength: 80 }}
                                    error={Boolean(fieldData?.error)}
                                    helperText={fieldData?.error}
                                    onChange={(text) => onChangeDefaultField && onChangeDefaultField(text, fieldData!.key)}
                                    label='Relationship :'
                                    placeholder='Enter the relationship'
                                />
                            }
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <StyledSecondaryButton data-test-id='toggle-field' disabled={disabled} onClick={() => toggleRelationship && toggleRelationship(fieldData.key)} >{(fieldData.isRelationDefault || fieldData.isRelationDefault === undefined) ? "Enter Custom Relationship" : "Select from Options"}</StyledSecondaryButton>
                            </Box>
                        </>
                    }
                </>
            }
            )}

            <Button data-test-id='add-field' disabled={disabled || disabledAddMore} onClick={() => addMoreFields && addMoreFields()} style={{ ...webStyles.addMoreBtn, color: disabledAddMore || disabled ? 'rgb(185, 185, 185)' : '#5B4595' }}>
                <AddCircleOutlineRoundedIcon style={{ marginRight: "5px" }} /> Add More
            </Button>
        </Box>
    </Box >
}

export default CommonMultipleAnswerField;

const StyledSubLabel = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#64748B',
        fontSize: '12px',
        lineHeight: '22px',
        fontFamily: "'Inter', sans-serif",
        width: '100%',
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
    },
    '&:hover': {
        display: "block",
        height: "auto",
        WebkitLineClamp: "unset",
        overflow: "visible",
        whiteSpace: "normal",
    }
})

const StyledSecondaryButton = styled(Button)({
    backgroundColor: '#DEDAEA',
    fontFamily: "'Inter', sans-serif",
    textTransform: 'capitalize',
    color: '#5B4595',
    margin: '10px 0 20px 0',
    padding: "6px 20px",
    '&:hover': {
        backgroundColor: '#DEDAEA'
    },
    '&.MuiButton-root.Mui-disabled': {
        color: '#64748B',
        backgroundColor: "#F1F5F9",
    },
    "@media(max-width:600px)": {
        width: '50%'
    }
})

const webStyles = {
    addMoreBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        color: '#5B4595',
        fontFamily: "'Inter', sans-serif",
        textTransform: 'capitalize',
        textAlign: 'start',
        paddingLeft: '0',
        fontWeight: 700
    } as React.CSSProperties,
    label: {
        color: '#334155',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '22px',
        fontFamily: "'Inter', sans-serif",
    }
}